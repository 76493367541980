import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import { AuthProvider } from './Auth/AuthContext'
import useAuthService from './Auth/AuthService'
import ProtectedRoute from './Auth/ProtectedRoute'

import Clients from './Pages/Clients'
import Add from './Pages/Add'
import Upload from './Pages/Upload'
import Login from './Pages/Login'
import Logout from './Pages/Logout'

import Sidebar from './Components/Sidebar'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { huHU } from '@material-ui/core/locale'
import { VERSION } from './Constants';
import View from './Pages/View'

function App() {

  const AuthService = useAuthService()
  const [auth, setAuth] = useState(AuthService.getCurrentUser())

  const setUser = (user) => {
    if (!user) AuthService.logout()
    setAuth(user)
  }

  const theme = createTheme({
    palette: {
      primary: { main: '#87AD72' },
    },
  }, huHU);

  return (
    <AuthProvider value={{ auth: auth, setAuth: setUser }}>
      <ThemeProvider theme={theme}>
        <div className="main_holder">
          <div className="version">{VERSION}</div>
          <Router>
            {auth &&
              <Sidebar className="sidebar_holder" />
            }
            <Switch>
              <div className="content_holder">
                <ProtectedRoute exact path="/" Component={Clients} />
                <ProtectedRoute path="/clients" Component={Clients} />
                <ProtectedRoute path="/view" Component={View} />
                <ProtectedRoute path="/add" Component={Add} />
                <ProtectedRoute path="/upload" Component={Upload} />
                <Route path="/login" render={(props) => <Login {...props} />} />
                <Route path="/logout" render={(props) => <Logout {...props} />} />
              </div>
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </AuthProvider>
  )
}



export default App;