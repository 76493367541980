import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Paper, Button, Tab, Tabs } from '@material-ui/core'

import MedicalRecord from '../Components/MedicalRecord'
import History from '../Components/History'
import ImagePreview from '../Components/ImagePreview'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useApi from '../API/API'

export default function View(props) {

    const API = useApi()

    const [medical, setMedical] = useState(props?.location?.state?.record)
    const [redirect, setRedirect] = useState(false)
    const [tab, setTab] = useState(0)

    const goBack = () => {
        setRedirect({
            pathname: "/clients",
            state: { from: props.location }
        })
    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <>
            {redirect &&
                <Redirect to={redirect} />
            }
            <Paper
                className='scrollbox_holder'
                square >
                <div className="col">
                    <Button style={{margin: '10px'}} ><ArrowBackIcon onClick={goBack} /></Button>
                    <Tabs
                        style={{ margin: 'auto' }}
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Általános információk" />
                        <Tab label="Kórtörténet" />
                        <Tab label="Képek" />
                        <Tab label="Felhasználó kezelése" />
                    </Tabs>
                </div>
                {tab === 0 &&
                    <MedicalRecord
                        medical={medical}
                    />
                }
                {tab === 1 &&
                    <History
                        medical={medical}
                    />
                }
                {tab === 2 &&
                    <ImagePreview
                        medical={medical}
                    />
                }
                {tab === 3 &&
                    <></>
                }
            </Paper>
        </>
    )
}