// export const API_URL = 'http://localhost:80/wp-json/'
// export const IMAGE_URL = 'http://localhost:80/wp-content/uploads/smd/'

export const API_URL = 'https://smilezor.hu/wp-json/'
export const IMAGE_URL = 'https://smilezor.hu/wp-content/uploads/smd/'

export const LOGO_URL = 'https://smilezor.hu/wp-content/uploads/2021/05/smilezorfav-1.png'
export const VERSION = '0.1.21-alpha'
export const TOKEN = 'SMD-AUTH'
export const A_SHIFT = [
    {
        value: 'bal',
        label: 'Balra tolva',
    },
    {
        value: 'közép',
        label: 'Középre tolva',
    },
    {
        value: 'jobb',
        label: 'Jobbra tolva',
    }
]
export const A_METHODS = [
    {
        value: 'javít',
        label: 'Javít',
    },
    {
        value: 'idealizál',
        label: 'Idealizál',
    },
    {
        value: 'fentart',
        label: 'Fentart',
    }
]

export const TEETH_NUMBER = [
    11, 12, 13, 14, 15, 16, 17, 18,
    21, 22, 23, 24, 25, 26, 27, 28,
    31, 32, 33, 34, 35, 36, 37, 38,
    41, 42, 43, 44, 45, 46, 47, 48
]

export const TEETH_METHOD = [
    "Csiszolás", "Húzás", "IPR", "Kezelés hossza (felső fogsor)", "Kezelés hossza (alsó fogsor)"
]