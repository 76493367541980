import { Button, Card, TextField } from "@material-ui/core"
import { useState } from "react"

import useApi from '../API/API'

import AddIcon from '@material-ui/icons/Add'

export default function History({medical}) {

    const API = useApi()

    const ID = medical.ID
    const [notes, setNotes] = useState(medical.notes)
    const [tmpNote, setTmpNote] = useState('')

    const noteUpload = () => {
        API.post('smd/v1/note', {
            ID: ID,
            message: tmpNote
        }).then(res => {
            setNotes([res.data, ...notes])
            setTmpNote('')
        })
    }

    return (
        <div className="scrollbox">
            <div className="scrollbox_inner">
                <div style={{ display: 'flex' }}>
                    <TextField
                        style={{ flex: 1 }}
                        className="add-input"
                        label="Megjegyzés"
                        multiline
                        maxRows={4}
                        name="tmpnote"
                        variant="outlined"
                        onChange={e => setTmpNote(e.target.value)}
                        value={tmpNote} />
                    <Button onClick={noteUpload}>
                        <AddIcon />
                    </Button>
                </div>
                {(notes && notes.length > 0) &&
                    notes.map((note, index) => {
                        return (
                            <Card key={index} className="history_card">
                                <p><em><strong>{note.date} </strong></em>{note.note}</p>
                            </Card>
                        )
                    })
                }
                {(!notes || notes.length < 0) &&
                    <h3 align="center"> Nincsenek megjegyzések </h3>
                }
            </div>
        </div>
    )

}