export default function MedicalRecord({ medical }) {

    if (medical) {
        if (medical.health_information && typeof medical.health_information === 'string') {
            medical.health_information = JSON.parse(medical.health_information)
        }
        if (medical.personal_information && typeof medical.personal_information === 'string') {
            medical.personal_information = JSON.parse(medical.personal_information)
        }
    }

    if (medical && medical.health_information && medical.personal_information) {
        return (
            <div className="scrollbox">
                <div className="scrollbox_inner">
                    <h3 align="center" className="subtitle"><strong> Személyes információk </strong></h3>
                    <p><strong> Név:</strong> {medical.personal_information.name}</p>
                    <p><strong> Születési dátum:</strong> {medical.personal_information.birthday}</p>
                    <p><strong> E-mail:</strong> <a href={'mailto:' + medical.personal_information.email}>{medical.personal_information.email}</a></p>
                    <p><strong> Cím:</strong> {medical.personal_information.address}</p>
                    <p><strong> Telefon:</strong> <a href={'tel:' + medical.personal_information.phone}>{medical.personal_information.phone}</a></p>

                    <h3 align="center" className="subtitle"><strong> Egészségügyi információk</strong></h3>
                    <p><strong> Kiskönyv száma:</strong> {medical.health_information.book_no}</p>
                    <p><strong> Feltöltve:</strong> {medical.date_created}</p>
                    <p><strong> Kezelés ára:</strong> {medical.health_information.price_a} Ft </p>
                    <p><strong> Fizetve ára:</strong> {medical.health_information.price_b} Ft </p>

                    <p><strong> Felső középvonal:</strong> {medical.health_information.upper_shif}</p>
                    <p><strong> Alsó középvonal:</strong> {medical.health_information.lower_shift}</p>

                    <p><strong> Felső középvonal:</strong> {medical.health_information.upper}</p>
                    <p><strong> Alsó középvonal:</strong> {medical.health_information.lower}</p>
                    <p><strong> Overjet:</strong> {medical.health_information.overjet}</p>
                    <p><strong> Overbite:</strong> {medical.health_information.overbite}</p>
                    <p><strong> Hátsó keresztharapás:</strong> {medical.health_information.cross_bite}</p>

                    <p><strong> Pótlandó fogak:</strong> {medical.health_information?.implement?.join(', ')}</p>
                    <p><strong> Eltávolítandó fogak:</strong> {medical.health_information?.remove?.join(', ')}</p>
                    <p><strong> Rések fenntartása:</strong> {medical.health_information?.keep_gap?.join(', ')}</p>
                    {(medical.health_information?.plan && Object.keys(medical.health_information.plan)) &&
                        <h4 align="center" className="subtitle"><strong> Terv információk </strong></h4>
                    }
                    {(medical.health_information?.plan && Object.keys(medical.health_information.plan)) &&
                        Object.keys(medical.health_information.plan).map(e => {
                            return (
                                <>
                                    <p><strong>{e}</strong></p>
                                    <DisplayPlan plan={medical.health_information.plan[e]} />
                                </>
                            )
                        })
                    }
                </div>
            </div>
        )
    } else {
        return (<></>)
    }

    function DisplayPlan({ plan }) {
        return (
            <div style={{ marginBottom: '20px' }}>
                {
                    plan.map(e => {
                        return (
                            <>
                                <p>
                                    <strong>
                                        {e.teeth_action}:&nbsp;&nbsp;
                                    </strong>
                                    {e.teeth_number}
                                </p>
                            </>
                        )
                    })
                }
            </div>
        )
    }

}