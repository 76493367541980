const DataGridHU = {
  // Root
  noRowsLabel: 'Üres',
  noResultsOverlayLabel: 'Nincs találat.',
  errorOverlayDefaultLabel: 'Hiba.',

  // Density selector toolbar button text
  toolbarDensity: 'Sűrűség',
  toolbarDensityLabel: 'Sűrűség',
  toolbarDensityCompact: 'Kompakt',
  toolbarDensityStandard: 'Általános',
  toolbarDensityComfortable: 'Kényelmes',

  // Columns selector toolbar button text
  toolbarColumns: 'Oszlopok',
  toolbarColumnsLabel: 'Oszlopok kiválasztása',

  // Filters toolbar button text
  toolbarFilters: 'Szűrők',
  toolbarFiltersLabel: 'Szűrők mutatása',
  toolbarFiltersTooltipHide: 'Szűrők elrejtése',
  toolbarFiltersTooltipShow: 'Szűrők mutatása',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktív szűrők` : `${count} aktív aktív`,

  // Export selector toolbar button text
  toolbarExport: 'Exportálás',
  toolbarExportLabel: 'Exportálás',
  toolbarExportCSV: 'Letöltés mint CSV',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Oszlop keresése',
  columnsPanelTextFieldPlaceholder: 'Oszlop neve',
  columnsPanelDragIconLabel: 'Oszlopok újrarendezése',
  columnsPanelShowAllButton: 'Összes mutatása',
  columnsPanelHideAllButton: 'Összes elrejtése',

  // Filter panel text
  filterPanelAddFilter: 'Szűrő hozzáadása',
  filterPanelDeleteIconLabel: 'Törlés',
  filterPanelOperators: 'Műveletek',
  filterPanelOperatorAnd: 'És',
  filterPanelOperatorOr: 'Vagy',
  filterPanelColumns: 'Oszlopok',
  filterPanelInputLabel: 'Érték',
  filterPanelInputPlaceholder: 'Szűrő értéke',

  // Filter operators text
  filterOperatorContains: 'tartalmaz',
  filterOperatorEquals: 'egyenlő',
  filterOperatorStartsWith: 'kezdődik',
  filterOperatorEndsWith: 'végződik',
  filterOperatorIs: '',
  filterOperatorNot: 'nem',
  filterOperatorAfter: 'után',
  filterOperatorOnOrAfter: 'pontosan vagy előtte',
  filterOperatorBefore: 'előtt',
  filterOperatorOnOrBefore: 'pontosan vagy utánna',
  filterOperatorIsEmpty: 'üres',
  filterOperatorIsNotEmpty: 'nem üres',

  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',

  // Column menu text
  columnMenuLabel: 'Menü',
  columnMenuShowColumns: 'Oszlopok mutatása',
  columnMenuFilter: 'Szűrés',
  columnMenuHideColumn: 'Elrejt',
  columnMenuUnsort: 'Rendezés megszüntetése',
  columnMenuSortAsc: 'Rendezés növekvő',
  columnMenuSortDesc: 'Rendezés csökkenő',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktív szűrők` : `${count} aktív szűrő`,
  columnHeaderFiltersLabel: 'Szűrők mutatása',
  columnHeaderSortIconLabel: 'Rendezés',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} Sorok kiválasztva`
      : `${count.toLocaleString()} Sor kiválasztva`,

  // Total rows footer text
  footerTotalRows: 'Sorok összesen:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} sorból: ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Jelölőnégyzet választás',

  // Boolean cell text
  booleanCellTrueLabel: 'Igaz',
  booleanCellFalseLabel: 'Hamis',

  // Used core components translation keys
  MuiTablePagination: {},
}

export default DataGridHU