import { useEffect, useState } from "react"
import { Button, CircularProgress, Paper, TextField, MenuItem } from "@material-ui/core"

import { IMAGE_URL, TEETH_NUMBER, TEETH_METHOD } from "../Constants"
import useApi from "../API/API"
import { useSnackbar } from "notistack"
import { AddSnack } from "../Components/Snacks"

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

export default function Upload(props) {

    const API = useApi()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [step, setStep] = useState(0)
    const [currentPlan, setCurrentPlan] = useState(`Terv-${new Date().toISOString().split('T')[0]}`)
    const [disabled, setDisabled] = useState(false)
    const [formData, setFormData] = useState([])
    const [plans, setPlans] = useState([])
    const [uploadState, setUploadState] = useState('')

    const [client, setClient] = useState(props?.location?.state?.client)


    const onInputChange = (e, i) => {
        formData[i][e.target.name] = e.target.value
        setFormData([...formData])
    }

    const addTeethNote = () => {
        if (formData.length === 0) {
            setFormData([...formData, { teeth_number: '', teeth_action: '' }])
        } else {
            if (formData[formData.length - 1].teeth_number !== '' && formData[formData.length - 1].teeth_action !== '') {
                setFormData([...formData, { teeth_number: '', teeth_action: '' }])
            }
        }
    }

    const deleteTeethNote = (i) => {
        setFormData(formData.filter((e, index) => i !== index))
    }

    const onSubmit = () => {
        setUploadState('in-progress')
        API.post('smd/v1/admin/plan', {
            ID: props?.location?.state?.ID,
            plan: formData,
            name: currentPlan
        })
            .then(() => {
                enqueueSnackbar('Páciens adatai sikeresen rögzültek.', AddSnack(closeSnackbar, 'success'))
                fileUpload()
            })
    }

    const fileUpload = () => {

        let files = []

        plans.forEach(file => {
            let data = new FormData()
            data.append('ID', props?.location?.state?.ID)
            data.append(currentPlan + '[]', file)
            files.push(API.post('smd/v1/files', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
                enqueueSnackbar(`${file.name} sikeresen feltöltve.`, AddSnack(closeSnackbar, 'success'))
            }))
        })

        Promise.allSettled(files).then(() => setUploadState('success'))
    }

    const deletePlan = (name) => {
        if (window.confirm('Biztosan törölni szeretnéd?')){
            API.delete('smd/v1/admin/plan', {
                ID: props?.location?.state?.ID,
                name: name
            }).then(() => {
                let state = {...client}
                state.pictures.filter(e => e.name !== name)
                delete state.health_information.plan[name]
                setClient(state)
            })
        }
    }

    if(client){
        return (
            <Paper
                className="scrollbox_holder"
                square
            >
                <div className="scrollbox">
                    <div className="scrollbox_inner">
                        <form className="add-form">
                            <div className={step === 0 ? "step-active" : "step"} >
                                <h3 align="center" className="subtitle"> Tervek </h3>
                                {(client?.health_information?.plan && Object.keys(client?.health_information?.plan).length > 0) &&
                                    Object.keys(client?.health_information?.plan).map((e) => {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <Button
                                                    style={{flex: 1}}
                                                    onClick={() => {
                                                        setCurrentPlan(e)
                                                        setFormData(client.health_information.plan[e])
                                                        setStep(step + 1)
                                                        setDisabled(true)
                                                    }}
                                                >{e}</Button>
                                                <Button
                                                    onClick={() => {
                                                        deletePlan(e)
                                                    }}
                                                ><DeleteIcon /></Button>
                                            </div>
                                        )
                                    })
                                }
                                <Button
                                    onClick={() => setStep(step + 1)}
                                >Új terv</Button>
                            </div>
                            <div className={step === 1 ? "step-active" : "step"} >
                                <TextField
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    label="Terv neve"
                                    name="plan"
                                    disabled={disabled}
                                    onChange={event => setCurrentPlan(event.target.value)}
                                    value={currentPlan} >
                                </TextField>
                                <h3 align="center" className="subtitle"> Fogászati információk </h3>
                                {
                                    formData.map((e, i) => {
                                        return (
                                            <div className="teeth-note" key={i}>
                                                <TextField
                                                    // select
                                                    label="Információ"
                                                    name="teeth_number"
                                                    onChange={event => onInputChange(event, i)}
                                                    value={e.teeth_number} >
                                                </TextField>
                                                <TextField
                                                    select
                                                    label="Tevékenység"
                                                    name="teeth_action"
                                                    onChange={event => onInputChange(event, i)}
                                                    value={e.teeth_action} >
                                                    {TEETH_METHOD.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <Button
                                                    onClick={() => deleteTeethNote(i)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </div>
    
                                        )
                                    })
                                }
                                <Button
                                    onClick={addTeethNote}
                                >
                                    <AddIcon />
                                </Button>
                            </div>
                            <div className={step === 2 ? "step-active" : "step"} >
                                <h3 align="center" className="subtitle"> Tervek feltöltése </h3>
                                <div className="col-center">
                                    <Button
                                        style={{ width: '50%' }}
                                        component="label" >
                                        Tervek feltöltése
                                        <input
                                            type="file"
                                            multiple
                                            hidden
                                            onChange={(e) => setPlans([...e.target.files])}
                                        />
                                    </Button>
                                </div>
                                {client.pictures.filter(e => e.type == currentPlan).length > 0 &&
                                    <h3 align="center" className="subtitle"> Feltöltött tervek </h3>
                                }
                                <div className="image-grid" style={{ marginTop: '20px' }}>
                                    {client.pictures.filter(e => e.type == currentPlan).length > 0 &&
                                        client.pictures.filter(e => e.type == currentPlan).map((image, index) => {
                                            return (
                                                <a key={index} href={IMAGE_URL + client.ID + '/' + image.filename} target="_blank" rel="noreferrer" className="image_card">
                                                    <img src={IMAGE_URL + client.ID + '/' + image.filename} alt="preview" />
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                                {plans.length > 0 &&
                                    <h3 align="center" className="subtitle"> Új tervek </h3>
                                }
                                <div className="image-grid" style={{ marginTop: '20px' }}>
                                    {
                                        plans.map((image, index) => {
                                            return (
                                                <a key={index} href={URL.createObjectURL(image)} target="_blank" rel="noreferrer" className="image_card">
                                                    <img src={URL.createObjectURL(image)} alt="preview" />
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </form >
                    </div >
                </div >
                <div className="col step-buttons" >
                    {step > 0 &&
    
                        <Button onClick={() => setStep(step - 1)}> <ArrowBackIcon className="button-icon" /> Előző </Button>
                    }
                    {step < 1 &&
                        <div></div>
                    }
                    {step < 2 &&
                        <Button onClick={() => setStep(step + 1)}> Következő <ArrowForwardIcon className="button-icon" /></Button>
                    }
                    {step === 2 &&
                        <>
                            <div></div>
    
                            <Button
                                style={{ position: 'relative' }}
                                className={uploadState}
                                onClick={onSubmit}
                            >
                                Küldés
                                <SaveIcon className="button-icon" />
                                {uploadState === 'in-progress' &&
                                    <CircularProgress size={24} />
                                }
                            </Button>
                        </>
                    }
                </div>
            </Paper >
        )
    } else {
        return <></>
    }
}