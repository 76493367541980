import React from 'react'
import { Redirect } from 'react-router-dom'

import { Paper } from '@material-ui/core'

import axios from 'axios'
import { API_URL } from '../Constants'
import authHeader from '../Auth/AuthHeader'


export default class ErrorBoundary extends React.Component {

    lastError = null;

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            errorInfo: '',
            hasError: false,
        }
    }

    componentDidMount() {
        window.onerror = (msg, url, line, column, error) => {
            this.logError({
                error,
            })
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        if (this.lastError && this.lastError.message === this.state.error.message) {
            return true;
        }
        this.setState({ errorInfo });
        this.logError(error, errorInfo)
    }

    render() {

        const { hasError, errorInfo } = this.state;
        const { children } = this.props;

        return hasError ? <ShowError errorInfo={errorInfo} /> : children;

    }

    logError = (error, info) => {

        this.lastError = error

        if (info){
            axios.post(
                API_URL + 'smd/v1/error',
                {
                    from: "Admin",
                    error,
                    info,
                }, {
                headers: {
                    Authorization: authHeader()
                }
            })
        }
    }
}

function ShowError({ errorInfo }) {
    return (
        <Paper
            className="scrollbox_holder"
            style={{width: '90vw', height: '90vh'}}
            square
        >
            <div className="scrollbox">
                <div className="scrollbox_inner">
                    <h1 align="center">Oops, valami hiba történt!</h1>
                    <div style={{ margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <div className="card-header">
                            <p>
                                Hiba történt az oldal betöltése közben.{' '}
                                <span
                                    style={{ cursor: 'pointer', color: '#0077FF' }}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Oldal újratöltése
                                </span>{' '}
                            </p>
                        </div>
                        <div style={{ cursor: 'pointer'}}>
                            <details className="error-details">
                                <summary>Kattints ide a hibák megjelenítéséhez.</summary>
                                {errorInfo && errorInfo.componentStack.toString()}
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    )
}