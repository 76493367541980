import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ErrorBoundary from './Components/ErrorBoundary'
import { SnackbarProvider } from 'notistack'
import Fade from '@material-ui/core/Fade'

import './styles.css'

ReactDOM.render(
  <ErrorBoundary>
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
      TransitionComponent={Fade}
      dense
      preventDuplicate >
      <App />
    </SnackbarProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)

reportWebVitals(console.log)