import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Paper, Tooltip, LinearProgress } from '@material-ui/core'
import { Chip } from '@material-ui/core'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridOverlay
} from '@material-ui/data-grid';
import DataGridHU from '../Util/DataGridHU'

import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CachedIcon from '@material-ui/icons/Cached'
import PublishIcon from '@material-ui/icons/Publish'

import useApi from '../API/API'

export default function Clients(props) {

    const API = useApi()

    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [redirect, setRedirect] = useState(false)

    const loadRows = () => {
        setLoading(true)
        API.get(`smd/v1/admin/list`)
            .then(res => {
                setRows(res.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadRows()
        // eslint-disable-next-line
    }, [])

    const handleOpen = (id) => {
        setLoading(true)
        API.get(`smd/v1/client?ID=${id}`)
            .then(res => {
                setRedirect({
                    pathname: "/view",
                    state: { from: props.location, record: res.data }
                })
            })
    }

    const handleEdit = (id) => {
        setLoading(true)
        API.get(`smd/v1/client?ID=${id}`)
            .then(res => {
                setRedirect({
                    pathname: "/add",
                    state: { from: props.location, client: res.data }
                })
            })
    }

    const handleUpload = (id) => {
        setLoading(true)
        API.get(`smd/v1/client?ID=${id}`)
            .then(res => {
                setRedirect({
                    pathname: "/upload",
                    state: { from: props.location, client: res.data, ID: id }
                })
            })
    }
 
    const handleDelete = (id) => {
        setLoading(true)
        API.delete(`smd/v1/client?ID=${id}`)
            .then(() => {
                setRows(rows.filter(e => e.id !== id))
                setLoading(false)
            })
    }

    function HeaderToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={() => loadRows()} >
                    <CachedIcon color='primary' />
                </Button>
            </GridToolbarContainer>
        );
    }

    function LoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        );
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 80, disableColumnMenu: true },
        { field: 'book_no', headerName: 'Kiskönyv', width: 120, disableColumnMenu: true },
        {
            field: 'doctor',
            headerName: 'Orvos',
            flex: 4,
            disableColumnMenu: true,
        },
        {
            field: 'name',
            headerName: 'Név',
            flex: 4,
            disableColumnMenu: true,
        },
        {
            field: 'email',
            headerName: 'E-mail',
            flex: 4,
            disableColumnMenu: true,
            renderCell: (params) => {
                let href = 'mailto:' + params.value
                return <Tooltip title="E-mail küldése"><a href={href}>{params.value}</a></Tooltip>
            }
        },
        {
            field: 'status',
            headerName: 'Státusz',
            flex: 2,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Chip
                    style={{ color: 'white', width: '100%' }}
                    label={params.value}
                    color='primary'
                />
            )
        },
        {
            field: 'actions',
            headerName: 'Műveletek',
            flex: 5,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <Tooltip title="Megtekintés">
                        <Button onClick={() => { handleOpen(params.id) }}><VisibilityIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Szerkesztés">
                        <Button onClick={() => { handleEdit(params.id) }}><CreateIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Tervek feltöltése">
                        <Button onClick={() => { handleUpload(params.id) }}><PublishIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Törlés">
                        <Button onClick={() => { handleDelete(params.id) }}><DeleteIcon /></Button>
                    </Tooltip>
                </>
            )
        }

    ]

    return (
        <>
            {redirect &&
                <Redirect to={redirect} />
            }
            <Paper
                className='scrollbox_holder'
                square >
                <DataGrid
                    localeText={DataGridHU}
                    rows={rows}
                    columns={columns}
                    pagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35, 40]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pageSize={pageSize}
                    components={{
                        Toolbar: HeaderToolbar,
                        LoadingOverlay: LoadingOverlay
                    }}
                    loading={loading}
                    disableSelectionOnClick
                />
            </Paper>
        </>
    )
}